import * as actionTypes from './actionTypes.js';
import {client} from '../../index';
import {COUNTRIES_QUERY} from "../../graphql/queries";

export const countriesFetchDataStart = (isLoading) => {
    return {
        type: actionTypes.FETCH_COUNTRIES_START,
        isLoading: isLoading
    }
};
export const countriesFetchDataSuccess = (countries) => {
    return {
        type: actionTypes.FETCH_COUNTRIES_SUCCESS,
        payload: countries
    }
};
export const countriesFetchDataError = (isError) => {
    return {
        type: actionTypes.FETCH_COUNTRIES_ERROR,
        isError: isError
    }
};

export const countriesFetchData = () => {
    return (dispatch, newState) => {
        return new Promise((resolve, reject) => {
            dispatch(countriesFetchDataStart(true));
            client.query({
                query: COUNTRIES_QUERY
            }).then(response => {
                dispatch(countriesFetchDataSuccess(response.data));
                dispatch(countriesFetchDataStart(false));
                resolve(newState());
            }).catch(() => {
                reject(newState());
                dispatch(countriesFetchDataError(true));
                //dispatch(countriesFetchDataStart(false));
                //console.error('Countries Fetch Data Error: ', errors);
            })
        });
    }
};