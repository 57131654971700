import * as actionTypes from './actionTypes';
import {client} from "../../index";
import {MANUFACTURERS_QUERY} from "../../graphql/queries";
import {categoriesFetchData} from "./categories";
import {iconsFetchData} from "./icons";
import {countriesFetchData} from "./countries";

export const manufacturersFetchDataStart = (isLoading) => {
    return {
        type: actionTypes.FETCH_MANUFACTURERS_START,
        isLoading: isLoading
    }
};
export const manufacturersFetchDataSuccess = (manufacturers) => {
    return {
        type: actionTypes.FETCH_MANUFACTURERS_SUCCESS,
        payload: manufacturers
    }
};
export const manufacturersFetchDataError = (isError) => {
    return {
        type: actionTypes.FETCH_MANUFACTURERS_ERROR,
        isError: isError
    }
};
export const manufacturersFetchData = () => {
    return (dispatch, newState) => {
        return new Promise((resolve, reject) => {
            dispatch(manufacturersFetchDataStart(true));
            client.query({
                query: MANUFACTURERS_QUERY,
                fetchPolicy: 'no-cache',
            }).then(response => {
                dispatch(manufacturersFetchDataSuccess(response.data));
                dispatch(manufacturersFetchDataStart(false));
                resolve(newState());
            }).catch(errors => {
                dispatch(manufacturersFetchDataError(true, errors));
                dispatch(manufacturersFetchDataStart(false));
                console.error('Manufacturers Fetch Data Error: ', errors);
                reject(newState());
            });
        })
    }
};

export const fetchProfileData = () => (dispatch) => {
    return Promise.all([
        dispatch(iconsFetchData()),
        dispatch(categoriesFetchData()),
        dispatch(countriesFetchData()),
        dispatch(manufacturersFetchData())
    ]);
};

export const saveProfileToStore = (editedManufacturer) => {
    return {
        type: actionTypes.SAVE_MANUFACTURER_EDITED_PROFILE,
        editedManufacturer: editedManufacturer
    }
};