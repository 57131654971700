import gql from "graphql-tag";

export const NOT_FOUND_PAGE_QUERY = gql`
    {
        notfoundPages {
            id
            title
            content
            browserTitle
            metaDescription
        }
    }
`;

export const LAYOUT_QUERY = gql`
    {
        faviconItems {
            favicon
        }
        headerItems {
            id
            mainMenu {
                id
                text
                type
                page {
                    id
                    title
                    draftTitle
                    slug
                    hasUnpublishedChanges
                    pageType
                }
                url
                style
                visibility
                order
            }
            secondaryMenu {
              id
              text
              type
              page {
                id
                title
                draftTitle
                slug
                hasUnpublishedChanges
                pageType
              }
              url
              style
              visibility
              order
            }
        }
        footerItems {
            id
            footerMenu {
              id
              text
              type
              page {
                id
                title
                draftTitle
                slug
                hasUnpublishedChanges
                pageType
              }
              url
              style
              visibility
            }
            footnote
        }
    }
`;

export const CONTACT_PAGE_QUERY = gql`
    {
        contactPages {
            id
            title
            intro
            slug
            nameTitle
            namePlaceholder
            nameRequiredErrorMessage
            companyNameTitle
            companyNamePlaceholder
            companyNameRequiredErrorMessage
            emailTitle
            emailPlaceholder
            emailRequiredErrorMessage
            emailInvalidErrorMessage
            phoneTitle
            phonePlaceholder
            phoneRequiredErrorMessage
            subjectTitle
            subjectPlaceholder
            subjectRequiredErrorMessage
            messageTitle
            messagePlaceholder
            messageRequiredErrorMessage
            submitButtonText
            submitSuccessText
            browserTitle
            metaDescription
        }
    }
`;

export const CONTENT_QUERY = gql`
        {
            contentPages {
                id
                slug
                title
                content
                browserTitle
                metaDescription
            }
        }
        `;

export const LOGIN_QUERY = gql`
    {
        loginPages {
            id
            title
            introText
            browserTitle
            metaDescription
            sidebarImage
            formTitle
            emailTitle
            emailPlaceholder
            passwordTitle
            passwordPlaceholder
            submitButtonText
            formFooterText
            sidebarTitle
            sidebarText
            backButtonText
            loginFailedErrorMessage
            emailInvalidErrorMessage
            emailRequiredErrorMessage
            passwordRequiredErrorMessage
            passwordTooShortErrorMessage           
            sidebarCallToAction {
                text
                page {
                    slug
                    pageType
                }
                url
            }
        }
    }
`;

export const REGISTER_QUERY = gql`
    {
        signupPages {
            id
            title
            introText
            sidebarImage
            formTitle
            firstNameTitle
            firstNamePlaceholder
            firstNameRequiredErrorMessage
            lastNameTitle
            lastNamePlaceholder
            lastNameRequiredErrorMessage
            emailTitle
            emailPlaceholder
            emailRequiredErrorMessage
            emailInvalidErrorMessage
            passwordTitle
            passwordPlaceholder
            passwordRequiredErrorMessage
            passwordTooShortErrorMessage
            submitButtonText
            submitSuccessText
            formFooterText
            sidebarTitle
            sidebarText
            backButtonText
            browserTitle
            metaDescription
            sidebarCallToAction {
                text
                page {
                    slug
                    pageType
                }
                url
            }
        }
    }
`;

export const FORGOT_PASSWORD_QUERY = gql`
    {
        forgotPasswordPages {
            id
            title
            introText
            sidebarImage
            formTitle
            emailTitle
            emailPlaceholder
            submitButtonText
            submitSuccessText
            formFooterText
            sidebarTitle
            sidebarText
            backButtonText
            emailInvalidErrorMessage
            emailRequiredErrorMessage
            browserTitle
            metaDescription
            sidebarCallToAction {
                text
                page {
                    slug
                    pageType
                }
                url
            }
        }
    }
`;

export const RESTORE_PASSWORD_QUERY = gql`
    {
        restorePasswordPages {
            id
            title
            introText
            sidebarImage
            formTitle
            passwordTitle
            passwordPlaceholder
            passwordRepeatTitle
            passwordRepeatPlaceholder
            submitButtonText
            staleTokenErrorText
            formFooterText
            sidebarTitle
            sidebarText
            backButtonText
            browserTitle
            metaDescription
            passwordInvalidErrorMessage
            passwordRequiredErrorMessage
            passwordRepeatInvalidErrorMessage
            passwordRepeatRequiredErrorMessage
            passwordDoNotMatchErrorMessage
            sidebarCallToAction {
                text
                page {
                    slug
                    pageType
                }
                url
            }
        }
    }
`;

        export const HOME_QUERY = gql`
    {
        homePages {
            id
            title
            subTitle
            live
            backgroundImage
            browserTitle
            metaDescription
            callToActions {
                id
                text
                page {
                    id
                    pageType
                    slug       
                    title
                }
                type
                visibility
            }
            searchPlaceholder
        }
    }
`;

export const FAQ_QUERY = gql`
    {
        faqPages {
            id
            title
            slug
            intro
            faqs
            browserTitle
            metaDescription
        }
    }
`;


export const UNEXPECTED_PAGE_QUERY = gql`
    {
        unexpectederrorPages {
            id
            title
            content
            browserTitle
            metaDescription
          }
    }
`;


export const MANUFACTURERS_PAGES_QUERY = gql`
    {
        manufacturersPages {
                id
                title
                slug
                searchTitle
                searchPlaceholder
                categoryTitle
                countryTitle
                noResults
                browserTitle
                metaDescription
                logoImagePlaceholder
        }
    }
`;

export const ACTIVATE_ACCOUNT_PAGE_QUERY = gql`
        {
                activateAccountPages {
                    id
                    title
                    content
                    browserTitle
                    metaDescription
                }
        }
`;

export const ACTIVATE_ACCOUNT_PAGE_DRAFT_QUERY = gql`
    query draftActivateAccountPage($id: Int!) {
        draftActivateAccountPage(id: $id) {
            id
            title
            content
            browserTitle
            metaDescription
        }
    }
`;

export const NOT_FOUND_PAGE_DRAFT_QUERY = gql`
    query DraftNotfoundPage($id: Int!) {
        draftNotfoundPage(id: $id) {
            id
            title
            content
            browserTitle
            metaDescription
        }
    }
`;

export const CONTACT_PAGE_DRAFT_QUERY = gql`
    query DraftContactPage($id: Int!) {
        draftContactPage(id: $id) {
            id
            title
            intro
            slug
            nameTitle
            namePlaceholder
            nameRequiredErrorMessage
            companyNameTitle
            companyNamePlaceholder
            companyNameRequiredErrorMessage
            emailTitle
            emailPlaceholder
            emailRequiredErrorMessage
            emailInvalidErrorMessage
            phoneTitle
            phonePlaceholder
            phoneRequiredErrorMessage
            subjectTitle
            subjectPlaceholder
            subjectRequiredErrorMessage
            messageTitle
            messagePlaceholder
            messageRequiredErrorMessage
            submitButtonText
            submitSuccessText
            browserTitle
            metaDescription
        }
    }
`;

export const CONTENT_DRAFT_QUERY = gql`
        query DraftContentPage($id: Int!) {
            draftContentPage(id: $id) {
                id
                slug
                title
                content
                browserTitle
                metaDescription
            }
        }
        `;

export const LOGIN_DRAFT_QUERY = gql`
    query DraftLoginPage($id: Int!) {
        draftLoginPage(id: $id) {
            id
            title
            introText
            browserTitle
            metaDescription
            sidebarImage
            formTitle
            emailTitle
            emailPlaceholder
            passwordTitle
            passwordPlaceholder
            submitButtonText
            formFooterText
            sidebarTitle
            sidebarText
            backButtonText
            loginFailedErrorMessage
            emailInvalidErrorMessage
            emailRequiredErrorMessage
            passwordRequiredErrorMessage
            passwordTooShortErrorMessage
            sidebarCallToAction {
                text
                page {
                    slug
                    pageType
                }
                url
            }
        }
    }
`;

export const REGISTER_DRAFT_QUERY = gql`
    query DraftSignupPage($id: Int!) {
        draftSignupPage(id: $id) {
            id
            title
            introText
            browserTitle
            metaDescription
            sidebarImage
            formTitle
            firstNameTitle
            firstNamePlaceholder
            lastNameTitle
            lastNamePlaceholder
            emailTitle
            emailPlaceholder
            passwordTitle
            passwordPlaceholder
            firstNameRequiredErrorMessage
            lastNameRequiredErrorMessage
            emailRequiredErrorMessage
            emailInvalidErrorMessage
            passwordRequiredErrorMessage
            passwordTooShortErrorMessage
            submitButtonText
            submitSuccessText
            formFooterText
            sidebarTitle
            sidebarText
            backButtonText
            sidebarCallToAction {
                text
                page {
                    slug
                    pageType
                }
                url
            }
        }
    }
`;

export const FORGOT_PASSWORD_DRAFT_QUERY = gql`
    query DraftForgotPasswordPage($id: Int!) {
        draftForgotPasswordPage(id: $id) {
            id
            title
            introText
            sidebarImage
            formTitle
            emailTitle
            emailPlaceholder
            submitButtonText
            submitSuccessText
            formFooterText
            sidebarTitle
            sidebarText
            backButtonText
            emailInvalidErrorMessage
            emailRequiredErrorMessage
            browserTitle
            metaDescription
            sidebarCallToAction {
                text
                page {
                    slug
                    pageType
                }
                url
            }
        }
    }
`;

export const RESTORE_PASSWORD_DRAFT_QUERY = gql`
    query DraftRestorePasswordPage($id: Int!) {
        draftRestorePasswordPage(id: $id) {
            id
            title
            introText
            sidebarImage
            formTitle
            passwordTitle
            passwordPlaceholder
            passwordRepeatTitle
            passwordRepeatPlaceholder
            passwordRequiredErrorMessage
            passwordInvalidErrorMessage
            submitButtonText
            staleTokenErrorText
            formFooterText
            sidebarTitle
            sidebarText
            backButtonText
            passwordRepeatInvalidErrorMessage
            passwordRepeatRequiredErrorMessage
            passwordRepeatInvalidErrorMessage
            passwordRepeatRequiredErrorMessage
            passwordDoNotMatchErrorMessage
            browserTitle
            metaDescription
            sidebarCallToAction {
                text
                page {
                    slug
                    pageType
                }
                url
            }
        }
    }
`;

export const HOME_DRAFT_QUERY = gql`
    query DraftHomePage($id: Int!) {
        draftHomePage(id: $id) {
            id
            title
            subTitle
            live
            backgroundImage
            browserTitle
            metaDescription
            callToActions {
                id
                text
                page {
                    id
                    pageType
                    slug
                    title
                }
                type
                visibility
            }
            searchPlaceholder
        }
    }
`;

export const FAQ_DRAFT_QUERY = gql`
    query DraftFaqPage($id: Int!) {
        draftFaqPage(id: $id) {
            id
            title
            slug
            intro
            faqs
            browserTitle
            metaDescription
        }
    }
`;


export const UNEXPECTED_PAGE_DRAFT_QUERY = gql`
    query DraftUnexpectederrorPage($id: Int!) {
        draftUnexpectederrorPage(id: $id) {
            id
            title
            content
            browserTitle
            metaDescription
          }
    }
`;


export const MANUFACTURERS_PAGE_DRAFT_QUERY = gql`
    query DraftManufacturersPage($id: Int!) {
        draftManufacturersPage(id: $id) {
                id
                title
                slug
                searchTitle
                searchPlaceholder
                categoryTitle
                countryTitle
                noResults
                browserTitle
                metaDescription
                logoImagePlaceholder
        }
    }
`;


export const MANUFACTURERS_QUERY = gql`
    {
          manufacturers{
                id
                companyName
                headerImage
                icon
                country
                countryName
                introduction
                categoriesNames
                slug
                categories {
                        id
                        name
                }
                documents{
                        id
                        title
                        thumbnail
                        download
                        order
                }
                sections{
                        id
                        title
                        icon{
                              id
                              image
                              text
                        }
                        text
                        order
                }
                  
        }
    }
`;

export const MANUFACTURER_QUERY = gql`
    query manufacturer($id: Int!) {
          manufacturer(id: $id){
                id
                companyName
                headerImage
                icon
                country
                introduction
                categories {
                        id
                        name
                }
                documents{
                        id
                        title
                        thumbnail
                        download
                        order
                }
                sections{
                        id
                        title
                        icon
                        text
                        order
                }
                  
        }
    }
`;

export const CATEGORY_ITEMS_QUERY = gql`
        {
          categoryItems{
            id
            name
          }
        }
`;

export const COUNTRIES_QUERY = gql`
        {
          countries{
            key
            value
          }
        }
`;

export const MANUFACTURER_PROFILE_PAGE_QUERY = gql`
        {
          manufacturerProfilePages{
            id
            title
            slug
            live
            searchDescription
            browserTitle
            editPageTitle
            metaDescription
            headerImageTitle
            headerImageSubTitle
            headerImageUploadText
            headerImageRemoveText
            companyInfoTitle
            companyNameTitle
            companyNamePlaceholder
            companyDescriptionTitle
            companyDescriptionPlaceholder
            countryTitle
            countryPlaceholder
            logoTitle
            logoPlaceholder
            logoUploadText
            logoRemoveText
            sectionsTitle
            sectionTitleTitle
            sectionTitlePlaceholder
            sectionIconTitle
            sectionIconPlaceholder
            sectionTextTitle
            sectionTextPlaceholder
            sectionTextHelpText
            addSectionButtonText
            removeSectionButtonText
            documentsTitle
            documentTitleTitle
            documentTitlePlaceholder
            documentLogoTitle
            documentLogoPlaceholder
            documentLogoUploadText
            documentLogoRemoveText
            documentDownloadTitle
            documentDownloadPlaceholder
            documentDownloadUploadText
            documentDownloadRemoveText
            addDocumentButtonText
            removeDocumentButtonText
            saveButtonText
            editProfileButtonText
            documentDownloadLinkText
            categoryTitle
            categoryPlaceholder
            sectionIconRequired
            sectionTextRequired
            sectionTitleRequired
            documentTitleRequired
            documentDownloadRequired
            logoImagePlaceholder
            headerImagePlaceholder
            documentLogoImagePlaceholder
            documentDownloadIcon
          }
        }
`;
export const MANUFACTURER_PROFILE_PAGE_DRAFT_QUERY = gql`
        query DraftManufacturerProfilePages($id: Int!){
          draftManufacturerProfilePage(id: $id){
            id
            title
            slug
            live
            searchDescription
            browserTitle
            editPageTitle
            metaDescription
            headerImageTitle
            headerImageSubTitle
            headerImageUploadText
            headerImageRemoveText
            companyInfoTitle
            companyNameTitle
            companyNamePlaceholder
            companyDescriptionTitle
            companyDescriptionPlaceholder
            countryTitle
            countryPlaceholder
            logoTitle
            logoPlaceholder
            logoUploadText
            logoRemoveText
            sectionsTitle
            sectionTitleTitle
            sectionTitlePlaceholder
            sectionIconTitle
            sectionIconPlaceholder
            sectionTextTitle
            sectionTextPlaceholder
            sectionTextHelpText
            addSectionButtonText
            removeSectionButtonText
            documentsTitle
            documentTitleTitle
            documentTitlePlaceholder
            documentLogoTitle
            documentLogoPlaceholder
            documentLogoUploadText
            documentLogoRemoveText
            documentDownloadTitle
            documentDownloadPlaceholder
            documentDownloadUploadText
            documentDownloadRemoveText
            addDocumentButtonText
            removeDocumentButtonText
            saveButtonText
            editProfileButtonText
            documentDownloadLinkText
            categoryTitle
            categoryPlaceholder
            sectionIconRequired
            sectionTextRequired
            sectionTitleRequired
            documentTitleRequired
            documentDownloadRequired
            logoImagePlaceholder
            headerImagePlaceholder
            documentLogoImagePlaceholder
            documentDownloadIcon
          }
        }
`;

export const ICONS_QUERY = gql`
        {
                icons{
                    id
                    image
                    text
                }
        }
`;

export const GET_USER_MANUFACTURER_ID_QUERY = gql`
        {
                  getUserManufacturerIdForLocalStorage{
                        id
                  }
        }
`;


export const REQUEST_FOR_QUOTATIONS_PAGES_QUERY = gql`
        {
          requestForQuotationPages{
            nameTitle
            namePlaceholder
            nameRequiredErrorMessage
            companyNameTitle
            companyNamePlaceholder
            companyNameRequiredErrorMessage
            emailTitle
            emailPlaceholder
            emailRequiredErrorMessage
            phoneTitle
            phonePlaceholder
            id
            path
            depth
            numchild
            title
            draftTitle
            slug
            live
            hasUnpublishedChanges
            searchDescription
            browserTitle
            metaDescription
            pagePtr {
              id
            }
            introTitle
            introText
            keywordsTitle
            keywordsPlaceholder
            categoryTitle
            categoryPlaceholder
            descriptionTitle
            descriptionPlaceholder
            attachmentsTitle
            attachmentsPlaceholder
            attachmentsUploadText
            attachmentsRemoveText
            preferredCurrencyTitle
            preferredCurrencyPlaceholder
            preferredUnitPriceTitle
            preferredUnitPricePlaceholder
            preferredShippingAgreementTitle
            preferredShippingAgreementPlaceholder
            destinationPortTitle
            destinationPortPlaceholder
            paymentMethodTitle
            paymentMethodPlaceholder
            privacyPolicyCheckboxText
            submitButtonText
            submitSuccessText
            keywordsRequiredErrorMessage
            categoryRequiredErrorMessage
            descriptionRequiredErrorMessage
            paymentMethodRequiredErrorMessage
            destinationPortRequiredErrorMessage
            preferredCurrencyRequiredErrorMessage
            preferredUnitPriceRequiredErrorMessage
            preferredShippingAgreementRequiredErrorMessage
            privacyPolicyRequiredErrorMessage
          }
        }
`;


export const REQUEST_FOR_QUOTATIONS_PAGES_DRAFT_QUERY = gql`
        query DraftRequestForQuotationPages($id: Int!){
          draftRequestForQuotationPages(id: $id){
            nameTitle
            namePlaceholder
            nameRequiredErrorMessage
            companyNameTitle
            companyNamePlaceholder
            companyNameRequiredErrorMessage
            emailTitle
            emailPlaceholder
            emailRequiredErrorMessage
            phoneTitle
            phonePlaceholder
            id
            path
            depth
            numchild
            title
            draftTitle
            slug
            live
            hasUnpublishedChanges
            searchDescription
            browserTitle
            metaDescription
            pagePtr {
              id
            }
            introTitle
            introText
            keywordsTitle
            keywordsPlaceholder
            categoryTitle
            categoryPlaceholder
            descriptionTitle
            descriptionPlaceholder
            attachmentsTitle
            attachmentsPlaceholder
            attachmentsUploadText
            attachmentsRemoveText
            preferredCurrencyTitle
            preferredCurrencyPlaceholder
            preferredUnitPriceTitle
            preferredUnitPricePlaceholder
            preferredShippingAgreementTitle
            preferredShippingAgreementPlaceholder
            destinationPortTitle
            destinationPortPlaceholder
            paymentMethodTitle
            paymentMethodPlaceholder
            privacyPolicyCheckboxText
            submitButtonText
            submitSuccessText
            keywordsRequiredErrorMessage
            categoryRequiredErrorMessage
            descriptionRequiredErrorMessage
            paymentMethodRequiredErrorMessage
            destinationPortRequiredErrorMessage
            preferredCurrencyRequiredErrorMessage
            preferredUnitPriceRequiredErrorMessage
            preferredShippingAgreementRequiredErrorMessage
            privacyPolicyRequiredErrorMessage
          }
        }
`;


export const REQUEST_FOR_QUOTATIONS_DATA_QUERY = gql`
        {
          shippingAgreements{
            id
            value
            name
          }
          currencies{
            id
            name
            value
          }
          paymentMethods{
            id
            value
            shortName
            name
          }
  }
`;

