import * as actionTypes from "../actions/actionTypes";

const initialState = {
  authenticated: false
};

export const authorizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTHORIZATION_SIGNIN:
      return {  ...state, authenticated: true, token: action.token };
    case actionTypes.AUTHORIZATION_SIGNOUT:
      return {};
    case actionTypes.SAVE_MANUFACTURER_ID_TO_STATE:
      return { ...state, manufacturerId: action.manufacturerId };
    default:
      return state;
  }
};
