import * as actionTypes from './actionTypes.js';
import {client} from '../../index';
import {CATEGORY_ITEMS_QUERY} from "../../graphql/queries";

export const categoriesFetchDataStart = (isLoading) => {
    return {
        type: actionTypes.FETCH_CATEGORIES_START,
        isLoading: isLoading
    }
};
export const categoriesFetchDataSuccess = (categories) => {
    return {
        type: actionTypes.FETCH_CATEGORIES_SUCCESS,
        payload: categories
    }
};
export const categoriesFetchDataError = (isError) => {
    return {
        type: actionTypes.FETCH_CATEGORIES_ERROR,
        isError: isError
    }
};
export const categoriesFetchData = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(categoriesFetchDataStart(true));
            client.query({
                query: CATEGORY_ITEMS_QUERY
            }).then(response => {
                dispatch(categoriesFetchDataSuccess(response.data));
                dispatch(categoriesFetchDataStart(false));
                resolve();
            }).catch(() => {
                dispatch(categoriesFetchDataStart(false));
                dispatch(categoriesFetchDataError(true));
                reject();
            })
        })

    }
};