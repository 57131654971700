import './App.scss';
import React, {Component} from 'react';
import {RouterGuard} from "react-router-guard";
import config from "./routerConfig";
import {BreakpointProvider, setDefaultBreakpoints} from 'react-socks';
import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import {connect} from 'react-redux';
import * as actionCreators from './store/actions/index';
import {saveTokenToState, saveManufacturerIdToStore} from "./store/actions/index";
import {func} from "prop-types";
import WhiteScreenLoading from "./components/Shared/WhiteScreenLoading";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#ff595e',
        },
        secondary: {
            main: '#8150c3'
        },
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: '#ff595e',
            }
        }
    }
});

setDefaultBreakpoints([
  { xs: 0 },
  { sm: 540 },
  { md: 720 },
  { lg: 1024 },
  { xl: 1200 }
]);

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {isLoading: true};
    }

    componentDidMount() {
        const token = localStorage.getItem('token');
        const manufacturerId = localStorage.getItem('manufacturerId');
        this.props.fetchLayout().then(() => {
            this.setState({isLoading: false})
        });
        // We need to update application state if the token or manufacturerId exists
        if (token) this.props.saveTokenToState(token);
        if (manufacturerId) this.props.saveManufacturerIdToStore(manufacturerId);
    }

    render()  {
        // There is Bug on IPhone XS with empty space above header
        // if(document.documentElement.classList.contains('no-isiphone')){
        //     window.addEventListener('resize', () => {
        //         // We execute the same script as before
        //         let vh = window.innerHeight * 0.01;
        //         document.documentElement.style.setProperty('--vh', `${vh}px`);
        //     });
        // }
        if(this.state.isLoading){
            return <WhiteScreenLoading/>;
        }
        return (
            <ThemeProvider theme={theme}>
                <BreakpointProvider>
                    <RouterGuard config={config} />
                </BreakpointProvider>
            </ThemeProvider>
        );
    }
}

App.propTypes = {
    fetchLayout: func,
    saveTokenToState: func,
    saveManufacturerIdToStore: func,
};

const mapDispatchToProps = dispatch => {
    return {
        fetchLayout: () => dispatch(actionCreators.fetchLayout()),
        saveTokenToState: (token) => dispatch(saveTokenToState(token)),
        saveManufacturerIdToStore: (token) => dispatch(saveManufacturerIdToStore(token)),
    }
};

export default connect(null, mapDispatchToProps)(App);

