export const AUTHORIZATION_SIGNIN = 'AUTHORIZATION_SIGNIN';
export const AUTHORIZATION_SIGNOUT = 'AUTHORIZATION_SIGNOUT';

export const FETCH_LAYOUT = 'FETCH_LAYOUT';

export const SAVE_MANUFACTURER_ID_TO_STATE = 'SAVE_MANUFACTURER_ID_TO_STATE';
export const FETCH_MANUFACTURERS_START = 'FETCH_MANUFACTURERS_START';
export const FETCH_MANUFACTURERS_SUCCESS = 'FETCH_MANUFACTURERS_SUCCESS';
export const FETCH_MANUFACTURERS_ERROR = 'FETCH_MANUFACTURERS_ERROR';
export const SAVE_MANUFACTURER_EDITED_PROFILE = 'SAVE_MANUFACTURER_EDITED_PROFILE';

export const FETCH_COUNTRIES_START = 'FETCH_COUNTRIES_START';
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS';
export const FETCH_COUNTRIES_ERROR = 'FETCH_COUNTRIES_ERROR';

export const FETCH_CATEGORIES_START = 'FETCH_CATEGORIES_START';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_ERROR = 'FETCH_CATEGORIES_ERROR';

export const FETCH_ICONS_START = 'FETCH_ICONS_START';
export const FETCH_ICONS_SUCCESS = 'FETCH_ICONS_SUCCESS';
export const FETCH_ICONS_ERROR = 'FETCH_ICONS_ERROR';

export const FETCH_REQUEST_FOR_QUOTATION_START = 'FETCH_REQUEST_FOR_QUOTATION_START';
export const FETCH_REQUEST_FOR_QUOTATION_ERROR = 'FETCH_REQUEST_FOR_QUOTATION_ERROR';
export const GET_SHIPPING_AGREEMENTS = 'GET_SHIPPING_AGREEMENTS';
export const GET_CURRENCIES = 'GET_CURRENCIES';
export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS';
export const SUBMIT_REQUEST_FOR_QUOTATION = 'SUBMIT_REQUEST_FOR_QUOTATION';
