export const INTERNAL_LINK = 'Internal';
export const EXTERNAL_LINK = 'External';
export const VISIBLE_LINK = 'Visible';
export const GUEST_LINK = 'Guest';
export const LOGGED_IN_LINK = 'LoggedIn';

export const PAGE_ROUTES = {
    "contact page": "/contact/",
    "page content": "/content/",
    "login": "/sign-in/",
    "sign up": "/sign-up/",
    'homepage': '/',
    'home page': '/',
    'faq page': '/faq/',
    'faqpage': '/faq/',
    'logout': '/logout/',
    'activate': '/activate/',
    'forgot_password': '/forgot-password/',
    'restore password': '/restore-password/',
    'content': '/content/',
    'not found page': '/404/',
    'manufacturers list page': '/manufacturers/',
    'manufacturer profile page': '/profile/',
    'request for quotation page': '/quotation/',
};

export const UNIQUE_PAGES = [
    'homepage',
    'home page',
    "sign up",
    "login",
    'logout',
    'activate',
    'forgot_password',
    'not found page',
    'manufacturers list page',
    'manufacturer profile page',
    'request for quotation page',
    'restore password',
];

export const LINK_TYPES = {
    'A_1': INTERNAL_LINK,
    'A_2': EXTERNAL_LINK,
};

export const LINK_VISIBILITY = {
    'A_1': VISIBLE_LINK,
    'A_2': GUEST_LINK,
    'A_3': LOGGED_IN_LINK
};

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL ? `${process.env.REACT_APP_BACKEND_URL}` : 'https://cms.textilespot.com/';
export const MEDIA_URL = process.env.REACT_APP_MEDIA_URL ? `${process.env.REACT_APP_MEDIA_URL}` : `${BACKEND_URL}media/`;
export const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL ? `${process.env.REACT_APP_GRAPHQL_URL}` : `${BACKEND_URL}graphql/`;
