import {gql} from "apollo-boost";

export const LOGIN_MUTATION = gql`
  mutation($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
    }
  }
`;


export const REGISTER_MUTATION = gql`
  mutation($email: String!, $password: String!, $lastName: String!, $firstName: String!) {
    createUser(input: {email: $email, password: $password, lastName: $lastName, firstName: $firstName}) {
      user {
        firstName
        lastName
        email
      }
      errors {
        field
        messages
      }
      clientMutationId
    }
  }
`;


export const FORGOT_PASSWORD_MUTATION = gql`
  mutation($email: String!) {
    forgotPassword(email: $email) {
      success
      errors
    }
  }
`;

export const RESTORE_PASSWORD_MUTATION = gql`
  mutation($password: String!, $passwordRepeat: String!, $uid: String!, $tokn: String!, ) {
    restorePassword(password: $password, passwordRepeat: $passwordRepeat, uid: $uid, tokn: $tokn) {
      success
      errors
      token
    }
  }
`;

export const CHECK_TOKEN_IS_STALE_MUTATION = gql`
  mutation($uid: String!, $tokn: String!) {
    checkTokenIsStale(uid: $uid, tokn: $tokn) {
      tokenIsStale
    }
  }
`;


export const CONTACT_MUTATION = gql`
  mutation($companyName: String!, $email: String!, $message: String!, $name: String!, $phone: String!, $subject: String!, $contactFormPk: Int!) {
    createContact(companyName: $companyName, email: $email, message: $message, name: $name, phone: $phone, subject: $subject, contactFormPk: $contactFormPk) {
        success
        errors
    }
  }
`;

export const EDIT_MANUFACTURER_MUTATION = gql`
  mutation(
    $id: Int, 
    $companyName: String, 
    $country: String, 
    $headerImage: Upload, 
    $icon: Upload, 
    $introduction: String, 
    $categories: String,
    $documents: [DocumentInput],
    $sections: [SectionInput],
  )
  {
    editManufacturer(
        id: $id,
        companyName: $companyName,
        country: $country,
        headerImage: $headerImage,
        introduction: $introduction,
        icon: $icon,
        categories: $categories,
        documents: $documents,
        sections: $sections,
     ) 
     {
      manufacturer {
        id
        companyName
        headerImage
        icon
        country
        countryName
        introduction
        slug
        categories {
            id
            name
        }
        documents{
            id
            title
            thumbnail
            download
        }
        sections{
            id
            title
            icon{
                id
                image
                text
            }
            text
        }
      }
    }
  }
`;
export const ACTIVATE_MUTATION = gql`
   mutation($registerToken: String!, $uid: String!) {
       activate(registerToken: $registerToken, uid: $uid) {
           success
           errors
           token
       }
   }
`;
export const CREATE_REQUEST_FOR_QUOTATION_MUTATION = gql`
    mutation(
        $categories: String!, 
        $description: String!, 
        $destination_port: String!,
        $i_agree_with_policy: Boolean!,
        $keywords: String!,
        $payment_method: String!,
        $preferred_currency: String!,
        $preferred_shipping_agreement: String!,
        $preferred_unit_price: String!,
        $attachments: Upload,
        $name: String!,
        $company_name: String!,
        $email: String!,
        $phone: String,
    ){
      createRequestForQuotation(
        categories: $categories,
        description: $description,
        destinationPort: $destination_port,
        iAgreeWithPolicy: $i_agree_with_policy,
        keywords: $keywords,
        paymentMethod: $payment_method,
        preferredCurrency: $preferred_currency,
        preferredShippingAgreement: $preferred_shipping_agreement,
        preferredUnitPrice: $preferred_unit_price,
        attachments: $attachments,
        name: $name,
        companyName: $company_name,
        email: $email,
        phone: $phone,
      ){
        success
      }
    }
`;