import * as actionTypes from "../actions/actionTypes";
const initialState ={
    manufacturersHasErrored: false,
    iconsHasErrored: false,
    categoriesHasErrored: false,
    countriesHasErrored: false,
    requestForQuotationHasErrored: false,
};
export const isErroredReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_MANUFACTURERS_ERROR:
            return {
                ...state,
                manufacturersHasErrored: action.isError
            };
        case actionTypes.FETCH_ICONS_ERROR:
            return {
                ...state,
                iconsHasErrored: action.isError
            };
        case actionTypes.FETCH_CATEGORIES_ERROR:
            return {
                ...state,
                categoriesHasErrored: action.isError
            };
        case actionTypes.FETCH_COUNTRIES_ERROR:
            return {
                ...state,
                countriesHasErrored: action.isError
            };
        case actionTypes.FETCH_REQUEST_FOR_QUOTATION_ERROR:
            return {
                ...state,
                requestForQuotationHasErrored: action.isError
            };

        default: {
            return state;
        }
    }
};