import * as actionTypes from '../actions/actionTypes';

const initialState = [];

export const manufacturersReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.FETCH_MANUFACTURERS_SUCCESS :
            return action.payload.manufacturers;

        case actionTypes.SAVE_MANUFACTURER_EDITED_PROFILE:
            return [
                ...state.filter(item =>
                    item.id.toString() !== action.editedManufacturer.id.toString()
                ),
                action.editedManufacturer

            ];

        default: {
            return state;
        }
    }
};