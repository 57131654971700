import * as actionTypes from "../actions/actionTypes";

const initialState = {
    manufacturersIsFetching: true,
    requestForQuotationIsFetching: true,
    iconsIsFetching: true,
    countriesIsFetching: true,
    categoriesIsFetching: true,
};

export const isFetchingReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_MANUFACTURERS_START:
            return {
                ...state,
                manufacturersIsFetching: action.isLoading
            };
        case actionTypes.FETCH_REQUEST_FOR_QUOTATION_START:
            return {
                ...state,
                requestForQuotationIsFetching: action.isLoading
            };
        case actionTypes.FETCH_ICONS_START:
            return {
                ...state,
                iconsIsFetching: action.isLoading
            };
        case actionTypes.FETCH_COUNTRIES_START:
            return {
                ...state,
                countriesIsFetching: action.isLoading
            };
        case actionTypes.FETCH_CATEGORIES_START:
            return {
                ...state,
                categoriesIsFetching: action.isLoading
            };
        default: {
            return state;
        }
    }
};
