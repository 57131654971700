import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {reducer as reduxFormReducer} from 'redux-form';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware, combineReducers, compose} from 'redux';
import thunk from 'redux-thunk';
import ApolloClient from 'apollo-client';
import {ApolloProvider} from 'react-apollo';
import {InMemoryCache} from "apollo-cache-inmemory";
import {createUploadLink} from "apollo-upload-client";

import {
    layoutReducer, authorizationReducer,
    manufacturersReducer, countriesReducer, categoriesReducer, iconsRecuder,
    currenciesReducer, paymentMethodsReducer, shippingAgreementsReducer,
    isErroredReducer, isFetchingReducer,
} from "./store/reducers";

import {GRAPHQL_URL} from "./constants";

const rootReducer = combineReducers({
    layout: layoutReducer,
    authorization: authorizationReducer,
    manufacturers: manufacturersReducer,
    countries: countriesReducer,
    categories: categoriesReducer,
    icons: iconsRecuder,
    currencies: currenciesReducer,
    paymentMethods: paymentMethodsReducer,
    shippingAgreements: shippingAgreementsReducer,
    isFetching: isFetchingReducer,
    isErrored: isErroredReducer,
    form: reduxFormReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export const client = new ApolloClient({
    cache: new InMemoryCache({addTypename: false}),
    link: createUploadLink({uri: GRAPHQL_URL}),
});

ReactDOM.render(
    <ApolloProvider client={client}>
        <Provider store={store}>
            <App/>
        </Provider>
    </ApolloProvider>
    ,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
