import * as actionTypes from '../actions/actionTypes';

export const layoutReducer = ( state = false, action ) => {
    switch ( action.type ) {
        case actionTypes.FETCH_LAYOUT :
            return action.payload;
        default: {
            return state;
        }
    }
};
