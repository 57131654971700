import * as actionTypes from './actionTypes';
import {client} from "../../index";
import {REQUEST_FOR_QUOTATIONS_DATA_QUERY} from "../../graphql/queries";
import {categoriesFetchData} from "./categories";
import {CREATE_REQUEST_FOR_QUOTATION_MUTATION} from "../../graphql/mutations";

export const getShippingAgreements = (payload) => {
    return {
        type: actionTypes.GET_SHIPPING_AGREEMENTS,
        payload: payload
    }
};

export const getCurrencies = (payload) => {
    return {
        type: actionTypes.GET_CURRENCIES,
        payload: payload
    }
};

export const getPaymentMethods = (payload) => {
    return {
        type: actionTypes.GET_PAYMENT_METHODS,
        payload: payload
    }
};

export const requestForQuotationFetchDataStart = (isLoading) => {
    return {
        type: actionTypes.FETCH_REQUEST_FOR_QUOTATION_START,
        isLoading: isLoading
    }
};
export const requestForQuotationFetchDataError = (isError) => {
    return {
        type: actionTypes.FETCH_REQUEST_FOR_QUOTATION_ERROR,
        isError: isError
    }
};
export const submitRequestForQuotationAction = (formValues) => {
    return {
        type: actionTypes.SUBMIT_REQUEST_FOR_QUOTATION,
        formValues: formValues
    }
};

export const fetchRequestForQuotationFormData = () => {
    return (dispatch) => {
        return client.query({
            query: REQUEST_FOR_QUOTATIONS_DATA_QUERY,
            //fetchPolicy: 'no-cache',
            //errorPolicy: 'all',
        }).then(response => {
            Promise.all([
                dispatch(getShippingAgreements(response.data.shippingAgreements)),
                dispatch(getPaymentMethods(response.data.paymentMethods)),
                dispatch(getCurrencies(response.data.currencies)),
                dispatch(categoriesFetchData()),
                dispatch(requestForQuotationFetchDataStart(false)),
            ]);
        }).catch(errors => {
            dispatch(requestForQuotationFetchDataError(true));
            dispatch(requestForQuotationFetchDataStart(false));
            console.error('fetchRequestForQuotationData Error: ', errors);
        });
    }
};

export const onSubmitRequestForQuotation = (formValues) => (dispatch) => {
    return client.mutate({
        mutation: CREATE_REQUEST_FOR_QUOTATION_MUTATION,
        variables: formValues
    })
};