import * as actionTypes from './actionTypes';
import {client} from "../../index";
import {LAYOUT_QUERY} from "../../graphql/queries";
import {MEDIA_URL} from "../../constants";

export const saveLayout = ( res ) => {
    return {
        type: actionTypes.FETCH_LAYOUT,
        payload: res
    };
};

export const fetchLayout = () => {
    return (dispatch) => {
        return client.query({
            query: LAYOUT_QUERY
        }).then(response => {
            const headerItems = response.data.headerItems[0];
            const footerItems = response.data.footerItems[0];
            const favicon = response.data.faviconItems[0] ? MEDIA_URL + response.data.faviconItems[0].favicon : null;
            dispatch(saveLayout({headerItems, footerItems, favicon}));
        })
    }
};