import * as actionTypes from "../actions/actionTypes";

export const shippingAgreementsReducer = (state = false, action) => {

    switch (action.type) {
        case actionTypes.GET_SHIPPING_AGREEMENTS:
            return action.payload;

        default: return state;
    }
};