import * as actionTypes from './actionTypes.js';
import {client} from '../../index';
import {ICONS_QUERY} from "../../graphql/queries";

export const iconsFetchDataStart = (isLoading) => {
    return {
        type: actionTypes.FETCH_ICONS_START,
        isLoading: isLoading
    }
};
export const iconsFetchDataSuccess = (icons) => {
    return {
        type: actionTypes.FETCH_ICONS_SUCCESS,
        payload: icons
    }
};
export const iconsFetchDataError = (isError) => {
    return {
        type: actionTypes.FETCH_ICONS_ERROR,
        isError: isError
    }
};

export const iconsFetchData = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(iconsFetchDataStart(true));
            client.query({
                query: ICONS_QUERY
            }).then(response => {
                dispatch(iconsFetchDataSuccess(response.data));
                dispatch(iconsFetchDataStart(false));
                resolve();
            }).catch(()=>{
                dispatch(iconsFetchDataStart(false));
                dispatch(iconsFetchDataError(true));
                reject();
            })
        })

    }
};