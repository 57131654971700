import * as actionTypes from '../actions/actionTypes';

export const categoriesReducer = (state = [], action) => {
    switch (action.type) {
        case actionTypes.FETCH_CATEGORIES_SUCCESS:
           return action.payload.categoryItems.sort((a, b) => {
               // Sorting categories names alphabetically
               return a.name.toLowerCase() !== b.name.toLowerCase() ? a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1 : 0;
           });
        default:
            return state;
    }
};