import * as actionTypes from "../actions/actionTypes";

export const currenciesReducer = (state = false, action) => {
    switch (action.type) {
        case actionTypes.GET_CURRENCIES:
           return action.payload;

        default: return state;
    }
};
