import { dynamicWrapper } from "react-router-guard";


export default [
    {
        path: "/",
        component: dynamicWrapper(() => import("./layouts/MainLayouts")),
        routes: [
            {
                path: "/",
                exact: true,
                component: dynamicWrapper(() => import("./containers/Home/Home"))
            },
            {
                path: "/faq/:slug?",
                exact: true,
                component: dynamicWrapper(() => import("./containers/FAQ/Faq"))
            },
            {
                path: "/content/:slug",
                exact: true,
                component: dynamicWrapper(() => import("./containers/Content/Content"))
            },
            {
                path: "/sign-up/:slug?",
                exact: true,
                component: dynamicWrapper(() => import("./containers/SignUp/SignUp"))
            },
            {
                path: "/sign-in/:slug?",
                exact: true,
                component: dynamicWrapper(() => import("./containers/SignIn/SignIn"))
            },
            {
                path: "/forgot-password/:slug?",
                exact: true,
                component: dynamicWrapper(() => import("./containers/ForgotPassword/ForgotPassword"))
            },
            {
                path: "/restore-password/draft/:id",
                exact: true,
                component: dynamicWrapper(() => import("./containers/RestorePassword/RestorePassword"))
            },
            {
                path: "/restore-password/:uid/:token",
                exact: true,
                component: dynamicWrapper(() => import("./containers/RestorePassword/RestorePassword"))
            },
            {
                path: "/contact/:slug?",
                exact: true,
                component: dynamicWrapper(() => import("./containers/ContactUs/ContactUs"))
            },
            {
                path: "/manufacturers/",
                exact: true,
                component: dynamicWrapper(() => import("./containers/ManufacturersList/ManufacturersListPage"))
            },
            {
                path: "/manufacturers/draft/:id",
                exact: true,
                component: dynamicWrapper(() => import("./containers/ManufacturersList/ManufacturersListPage"))
            },
            {
                path: "/manufacturers/:categories/:countries/:keywords?",
                exact: true,
                component: dynamicWrapper(() => import("./containers/ManufacturersList/ManufacturersListPage"))
            },
            {
                path: "/profile/",
                exact: true,
                component: dynamicWrapper(() => import("./containers/Profile/ProfileView"))
            },
            {
                path: "/manufacturers/:slug",
                exact: true,
                component: dynamicWrapper(() => import("./containers/Profile/ProfileView"))
            },
            {
                path: "/edit/",
                exact: true,
                component: dynamicWrapper(() => import("./containers/Profile/ProfileEdit"))
            },
            {
               path: "/quotation/",
               exact: true,
               component: dynamicWrapper(() => import("./containers/RequestForQuotation/RequestForQuotation"))
            },
            {
               path: "/quotation/draft/:id",
               exact: true,
               component: dynamicWrapper(() => import("./containers/RequestForQuotation/RequestForQuotation"))
            },
            {
                path: "/404/:slug?",
                exact: true,
                component: dynamicWrapper(() => import("./containers/PageNotFound/PageNotFound"))
            },
            {
                path: "/unexpected/:slug?",
                exact: true,
                component: dynamicWrapper(() => import("./containers/UnexpectedPage/UnexpectedPage"))
            },
            // draft pages
            {
                path: "/draft/:id",
                exact: true,
                component: dynamicWrapper(() => import("./containers/Home/Home"))
            },
            {
                path: "/faq/draft/:id",
                exact: true,
                component: dynamicWrapper(() => import("./containers/FAQ/Faq"))
            },
            {
                path: "/content/draft/:id",
                exact: true,
                component: dynamicWrapper(() => import("./containers/Content/Content"))
            },
            {
                path: "/sign-up/draft/:id",
                exact: true,
                component: dynamicWrapper(() => import("./containers/SignUp/SignUp"))
            },
            {
                path: "/sign-in/draft/:id",
                exact: true,
                component: dynamicWrapper(() => import("./containers/SignIn/SignIn"))
            },
            {
                path: "/forgot-password/draft/:id",
                exact: true,
                component: dynamicWrapper(() => import("./containers/ForgotPassword/ForgotPassword"))
            },
            {
                path: "/contact/draft/:id",
                exact: true,
                component: dynamicWrapper(() => import("./containers/ContactUs/ContactUs"))
            },
            {
                path: "/profile/draft/:id",
                exact: true,
                component: dynamicWrapper(() => import("./containers/Profile/ProfileView"))
            },
            {
                path: "/404/draft/:id",
                exact: true,
                component: dynamicWrapper(() => import("./containers/PageNotFound/PageNotFound"))
            },
            {
                path: "/unexpected/draft/:id",
                exact: true,
                component: dynamicWrapper(() => import("./containers/UnexpectedPage/UnexpectedPage"))

            },
            {
               path: "/activate/draft/:id",
               exact: true,
               component: dynamicWrapper(() => import("./containers/Activate/Activate"))
            },
            {
               path: "/activate/:uid/:token",
               exact: true,
               component: dynamicWrapper(() => import("./containers/Activate/Activate"))
            },
            {
                path: "/**",
                exact: true,
                redirect: '/404'
            },
        ]
    }
];
