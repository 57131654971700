import * as actionTypes from "./actionTypes";
import {client} from "../../index";
import {GET_USER_MANUFACTURER_ID_QUERY} from "../../graphql/queries";

export const saveTokenToState = (token) => {
    return { type: actionTypes.AUTHORIZATION_SIGNIN, token: token };
};

export const saveManufacturerIdToStore = (id) => {
    return({type: actionTypes.SAVE_MANUFACTURER_ID_TO_STATE, manufacturerId: id});
};

export const signIn = (token) => {
    return (dispatch) => {
        localStorage.setItem('token', token);
        return client.query({
            query: GET_USER_MANUFACTURER_ID_QUERY,
            context: {
                headers: {
                    authorization: token ? `JWT ${token}` : ''
                },
            },
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        }).then(response => {
            let manufacturerId = 0;
            if(response.data.getUserManufacturerIdForLocalStorage.id){
              manufacturerId = response.data.getUserManufacturerIdForLocalStorage.id;
            }
            localStorage.setItem('manufacturerId', manufacturerId);
            dispatch(saveTokenToState(token));
            dispatch(saveManufacturerIdToStore(manufacturerId));
        }).catch(error=>console.error('GET_USER_MANUFACTURER_ID_QUERY error:', error));
    };
};

export const signOut = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('manufacturerId');
  return { type: actionTypes.AUTHORIZATION_SIGNOUT };
};
